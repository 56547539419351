<template>
		<v-combobox
			v-model="keywords"
			:label="label"
			multiple
			dense
			chips
			@input="update"
			hint="Press tab to complete, avoid using commas."
		></v-combobox>
</template>

<script>
import _isString from 'lodash/isString'
export default {
	name: "TiPillCombo",
	props: ['value', 'label', 'return_array'],
	data: () => ({
	    keywords:[]
	}),
	mounted(){
		if(_isString(this.value)){
			this.keywords = this.value.split(',')
		}
	},
	watch:{
		value(){
			if(_isString(this.value)){
				this.keywords = this.value.split(',')
			}
		},
		keywords(){
			this.update()
		}
	},
	methods: {
		update() {
			if(this.return_array){
				this.$emit('input', this.keywords)
			}
			else {
				this.$emit('input', this.keywords.join())
			}


		}
	}
}
</script>

<style scoped>

</style>