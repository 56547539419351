<template>
	<v-row>
		<v-col cols="12" lg="3">
			<v-text-field label="File name" v-model="value.filename" @change="changed = true"></v-text-field>
		</v-col>
		<v-col cols="12" md="3">
			<v-file-input
				@change="changed = true"
				v-model="document"
				:value="value"
				:label="theLabel"
				:prepend-icon="$icons.document"
			></v-file-input>
		</v-col>
		<v-col cols=12 md>
			<v-text-field readonly v-model="value.url" label="URL"></v-text-field>
		</v-col>
		<v-col cols="12" md="auto">
			<v-btn v-if="changed" color="success" elevation="2" fab small class="mr-4" @click="update">
				<v-icon>{{ $icons.save }}</v-icon>
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
import helperMixin from "@/mixins/helperMixin";

export default {
	name: "TiDocumentUpload",
	props: {
		value: Object,
		label: String,
		documentId: Number,
		showSlug: Boolean,
	},
	mixins: [helperMixin],
	computed: {
		theLabel() {
			return this.value.file || this.label
		}
	},
	data: () => ({
		document: null,
		document_filename: '',
		changed: false
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.loadDocument()
		},
		loadDocument() {
			if (this.documentId) {
				this.$api.documents.show(this.documentId)
					.then((response) => {
						this.document_filename = `${response.data.filename}`
					})
					.catch(error => {
						this.$toast.add(error.response.statusText, 'error')
					})
			} else {
				this.document_url = null
			}
		},
		update() {
			let form = new FormData
			form.append('document[id]', this.documentId)
			form.append('document[filename]', this.value.filename)
			form.append('document[slug]', this.value.slug)
			if (this.document) {
				form.append('document[document]', this.document)
			}
			if (this.value.id) {
				console.log('updating');
				this.$api.documents.update(this.value.id, form)
					.then(response => {
						this.$emit('input', response.data);
						this.changed = false
					})
					.catch(error => {
						this.$toast.add(error.response.statusText, 'error')
					})
			} else {
				console.log('creating');
				this.$api.documents.create(form)
					.then(response => {
						this.$emit('input', response.data);
						this.changed = false
					})
					.catch(error => {
						this.$toast.add(error.response.statusText, 'error')
					})
			}
		}
	}
}
</script>