// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	redirects:[],
	redirect: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getRedirects: ({ dispatch }) => {
		api.redirects.index()
			.then(response => dispatch('setRedirects', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getRedirect: ({ dispatch }, id) => {
		api.redirects.show(id)
			.then(response => dispatch('setRedirect', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
