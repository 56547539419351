// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	user_seniorities:[],
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getUserSeniorities: ({ dispatch }) => {
		api.user_seniority.index()
			.then(response => dispatch('setUser_seniorities', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
