// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	intermissions:[],
	intermission: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getIntermissions: ({ dispatch }) => {
		api.intermissions.index()
			.then(response => dispatch('setIntermissions', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getIntermission: ({ dispatch }, id) => {
		api.intermissions.show(id)
			.then(response => dispatch('setIntermission', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
