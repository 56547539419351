export default {
	login:'mdi-login',
	logout:'mdi-logout',
	cog:'mdi-cog',
	tools:'mdi-hammer-wrench',
	payments:'mdi-credit-card-outline',
	target:'mdi-bullseye-arrow',
	settings:'mdi-application-cog-outline',
	design: 'mdi-palette',
	redirects:'mdi-arrow-decision',
	redirect:'mdi-arrow-decision',
	dots:'mdi-dots-vertical',
	close:'mdi-close',
	check:'mdi-check',
	edit: 'mdi-pencil',
	plus: 'mdi-plus',
	new: 'mdi-plus',
	save: 'mdi-content-save',
	delete: 'mdi-delete',
	undo: 'mdi-undo',
	redo: 'mdi-redo',
	up: 'mdi-chevron-up',
	down: 'mdi-chevron-down',
	left: 'mdi-chevron-left',
	right: 'mdi-chevron-right',
	circle: 'mdi-checkbox-blank-circle',
	connect: 'mdi-axis-arrow',
	image: 'mdi-image',
	images: 'mdi-image-multiple',
	seo: 'mdi-search-web',
	link: 'mdi-link',
	content: 'mdi-card-text',
	dashboard: 'mdi-view-dashboard',
	user: 'mdi-account',
	user_groups: 'mdi-account-group',
	users: 'mdi-account-multiple',
	user_cancel: 'mdi-account-cancel',
	user_activate: 'mdi-account-check',
	team_member: 'mdi-account',
	team_members: 'mdi-account-multiple',
	pages: 'mdi-file-document-multiple-outline',
	page: 'mdi-file-document-outline',
	document: 'mdi-file-document-outline',
	brands: 'mdi-alphabetical-variant',
	brand: 'mdi-alphabetical-variant',
	tracks: 'mdi-road-variant',
	track: 'mdi-road-variant',
	course: 'mdi-archive-edit-outline',
	courses: 'mdi-archive-edit-outline',
	infographic: 'mdi-camera-burst',
	infographics: 'mdi-camera-burst',
	video: 'mdi-video',
	videos: 'mdi-video',
	intermission: 'mdi-video',
	intermissions: 'mdi-video',
	external: 'mdi-open-in-new',
	articles: 'mdi-card-text',
	article: 'mdi-card-text',
	podcasts: 'mdi-podcast',
	podcast: 'mdi-podcast',
	case_study: 'mdi-archive-eye',
	case_studies: 'mdi-archive-eye',
	certificates: 'mdi-certificate',
	certificate: 'mdi-certificate',
	diploma: 'mdi-certificate-outline',
	diplomas: 'mdi-certificate-outline',
	topic: 'mdi-alpha-t-box',
	topics: 'mdi-alpha-t-box',
	corporate: 'mdi-bank',
	corporates: 'mdi-bank',
	xapi_content: 'mdi-alpha-x-box',
	xapi_contents: 'mdi-alpha-x-box',
	testimonial: 'mdi-comment-quote-outline',
	testimonials: 'mdi-comment-quote-outline',
	user_invites: 'mdi-account-multiple-plus',
	open_link: 'mdi-open-in-new',
	csv: 'mdi-file-excel',
	skull: 'mdi-skull',
	reports: 'mdi-chart-box',
	report: 'mdi-poll',
	pivot: 'mdi-table-pivot',
	star: 'mdi-star',
	n: 'mdi-alpha-n-box',
	s: 'mdi-alpha-s-box',
	w: 'mdi-alpha-w-box',
	c: 'mdi-alpha-c-box',
	nominations: 'mdi-format-list-bulleted',
	podcast_award: 'mdi-trophy',
	up_box: 'mdi-arrow-up-box',
	down_box: 'mdi-arrow-down-box',
	filter: 'mdi-filter-variant',
	training: 'mdi-school'
}