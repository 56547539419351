// Pathify
import {make} from 'vuex-pathify'

// Data
const state = {
	pages:[],
	page: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = make.actions(state)

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
