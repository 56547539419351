<template>
	<v-menu v-model="menu" offset-y>
		<template v-slot:activator="{ on }">
			<v-text-field
				v-model="computedHexValue"
				v-on="on"
				readonly
				:clearable="true"
				:label="label"
			>
				<template v-slot:append-outer>
					<v-icon
						@click="menu = !menu"
						:style="iconStyle"
						style="border: 1px solid #555; border-radius: 50%;"
					>mdi-circle
					</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-color-picker
			v-model="computedHexValue"
			hide-sliders
			hide-inputs
			hide-canvas
			show-swatches
			:swatches="[['#2f5e5b', '#5099a5', '#7264b9','#ade041', '#eac2ce', '#d3555b']]"
			@input="updateColor"
		></v-color-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'TiColorPicker',
	props: {
		label: {
			type: String,
			default: 'Color',
		},
		value: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			menu: false,
			selectedColor: 'null',
		};
	},
	computed: {
		computedHexValue: {
			get(){
				console.log('GET: ', this.value)
				return this.value
			},
			set(value){
				console.log('SET: ', value)
				this.updateColor()
			}
		},
		iconStyle() {
			if (this.value) {
				return `color: ${this.value}`;
			}
		}

	},
	methods: {
		updateColor(value) {
			if(value?.hasOwnProperty('hex')){
				console.log('has hex', this.value)
				this.$emit('input', this.value.hex);
			} else {
				console.log('no hex', value)
				this.$emit('input', value);
			}
		},
	},
};
</script>