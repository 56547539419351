<template>
	<v-alert type="error" class="mb-10">
		This section is deprecated and will be removed soon. Please use the <v-btn href="https://admin2.targetinternet.com" color="charcoal" small target="_blank" class="ms-2">New Admin Portal</v-btn>.
	</v-alert>
</template>

<script>
export default {
	name: "NewPortalWarning"
}
</script>