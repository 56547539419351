// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';
import models from '../../models'

// Data
const state = {
	resources:[],
	resource: models.Resource(),
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getResources: ({ dispatch }) => {
		api.resources.index()
			.then(response => dispatch('setResources', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getResource: ({ dispatch }, id) => {
		api.resources.show(id)
			.then(response => dispatch('setResource', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
