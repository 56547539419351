<template>
	<div>
		<label v-if="!noTitle" class="v-label v-label--active theme--light" style="font-size: 12px;">{{ label }}</label>
		<editor
			api-key="sz90p415687x7ihp4a33zvu5c2pb2nruawnlidlvof5pzb62"
			:init="options"
			:value="value"
			@input="update"
		></editor>
	</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
	name: "TiEditor",
	props: {
		value: {
			required: true
		},
		label: {
			default: 'Content'
		},
		noTitle: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Editor
	},
	data() {
		return {
			options: {
				height: 600,
				images_upload_handler: this.imageUploadHandler,
				images_upload_base_path: 'http://localhost:3000',
				plugins: [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code fullscreen',
					'insertdatetime media table paste code help wordcount'
				],
				content_style: '* { font-family: sans-serif; font-weight: 300;} strong { font-weight: 600; }',
				branding: false,
				menubar: 'edit insert view format table tools help',
				menu: {
					view: {title: 'View', items: 'code'},
					insert: {title: 'Insert', items: 'image link media | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'},
					format: {title: 'Format', items: 'bold italic underline superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat'},
					tools: {title: 'Tools', items: 'code'},
					table: {title: 'Table', items: 'inserttable | cell row column | tableprops deletetable'}
				},
				toolbar: 'undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | media image link table | removeformat | customlink addId | help',
				automatic_uploads: 'true',
				media_dimensions: false,
				media_alt_source: false,
				setup: (editor) => {
					editor.ui.registry.addButton('customlink', {
						text: 'Insert Link',
						onAction: function () {
							editor.execCommand('mceCustomLink');
						}
					});
					editor.ui.registry.addButton('addId', {
						text: 'Add ID',
						onAction: function () {
							editor.execCommand('mceAddId');
						}
					});
					editor.addCommand('mceAddId', function () {
						// Check if there's selected content
						const selectedContent = editor.selection.getContent();

						if (selectedContent.trim() === "") {
							editor.windowManager.alert('Please select some content in the editor before adding an ID.');
							return;
						}

						// Get the currently selected content as a DOM element
						const selectedElement = editor.selection.getNode();

						// Get the user's input for the ID
						editor.windowManager.open({
							title: 'Add ID',
							body: {
								type: 'panel',
								items: [
									{
										type: 'input',
										name: 'id',
										label: 'ID'
									},
								],
							},
							buttons: [
								{
									type: 'cancel',
									text: 'Cancel',
								},
								{
									type: 'submit',
									text: 'Insert',
									primary: true,
								}
							],
							onSubmit: function (api) {
								const data = api.getData();
								const id = data.id.trim();

								// Convert the user's input to a URL-safe string
								let safeId = id.toLowerCase().replace(/ /g, '-');
								safeId = encodeURIComponent(safeId);

								// Add the ID as an attribute to the selected element
								selectedElement.setAttribute('id', safeId);

								// Display the safeId to the user
								// alert(`ID added: ${safeId}`); // You can use another UI component to display it if desired.

								// Close the panel
								api.close();

								// Create a modal dialog to display the safeId in a readonly input
								editor.windowManager.open({
									title: 'ID Added',
									body: {
										type: 'panel',
										items: [
											{
												type: 'htmlpanel', // A HTML panel component
												html: `ID added: <input type="text" value="#${safeId}" readonly style="border: 1px solid black; width: 100%; padding: 8px;">`,
											}
										],
									},
									buttons: [
										{
											type: 'cancel',
											text: 'Close',
											primary: true,
										}
									]
								});
							}
						});
					});


					editor.addCommand('mceCustomLink', function () {
						editor.windowManager.open({
							title: 'Insert Link',
							body: {
								type: 'panel',
								items: [
									{
										type: 'input',
										name: 'text',
										label: 'Text'
									},
									{
										type: 'input',
										name: 'href',
										label: 'URL'
									},
									{
										type: 'checkbox',
										name: 'target',
										label: 'Open in new tab'
									},
									{
										type: 'checkbox',
										name: 'addButtonClass',
										label: 'Add button class'
									}
								]
							},
							buttons: [
								{
									type: 'cancel',
									text: 'Cancel',
								},
								{
									type: 'submit',
									text: 'Insert',
									primary: true,
								}
							],
							onSubmit: function (api) {
								const data = api.getData();
								const text = data.text;
								const url = data.href.trim();
								const target = data.target ? '_blank' : '';
								const addButtonClass = data.addButtonClass;

								if (url) {
									let link = `<a href="${url}" target="${target}">${text}</a>`;

									if (addButtonClass) {
										link = `<a href="${url}" target="${target}" class="button">${text}</a>`;
									}

									editor.insertContent(link);
								}

								api.close();
							}
						});
					});
				}
			}
		};
	},
	methods: {
		update(newValue) {
			this.$emit('input', newValue);
		},
		imageUploadHandler(blobInfo, success, failure, progress) {
			let form = new FormData();
			form.append('image[image]', blobInfo.blob());
			this.$api.images.create(form)
				.then(response => {
					let baseUrl = localStorage.getItem('env') === 'Local' ? 'http://localhost:3000' : '';
					success(`${baseUrl}${response.data.location}`);
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error');
				});
		}
	}
};
</script>
