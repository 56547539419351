// Pathify
import {make} from 'vuex-pathify'
import api from '@/services/api'
import {toast} from '@/util/helpers';
import models from "../../models";

// Data
const state = {
	diplomas: [],
	diploma: models.Certification('Diploma'),
}

const getters = make.getters(state)


const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getDiplomas: ({dispatch}) => {
		api.diplomas.index()
			.then(response => dispatch('setDiplomas', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getDiploma: ({dispatch}, id) => {
		api.diplomas.show(id)
			.then(response => dispatch('setDiploma', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}


export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
