import store from '../store/index'
import _forEach from "lodash/forEach";
import vue from 'vue'
import {sync} from "vuex-pathify";

export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export const loading = {
	get: function(){
		return store.getters['app/loading']
	},
	set: function(value = false){
		store.commit('app/loading', value)
	}
}

export const toast = {
	add: (message, type='info') => store.commit('app/addToast', {message, type}),
	remove: () => store.commit('app/removeToast'),
	handleResponseErrors:(scope, error) => {
		// check if validation errors
		if(error.response.status === 422){
			_forEach(error.response.data, function(error, key) {
				let fieldName = key.replace(/_/ig, ' ')
				fieldName = fieldName[0].toUpperCase() + fieldName.substring(1)
				this.$toast.add(`${fieldName} ${error}`, 'error')
			}.bind(scope));
		}
		else if(error.response.status === 401){
			this.$toast.add('Unauthorized', 'error')
		}
		else {
			this.$toast.add(`${error.response.statusText} - You can check the console for me details`, 'error')
		}

	}
}