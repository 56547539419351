// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	testimonials: [],
	testimonial: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getTestimonials: (context) =>
		api.testimonials.index()
			.then((response) => context.commit('testimonials', response.data))
			.catch((error) => console.log(error)),

	getTestimonial: (context, id) =>
		api.testimonials.show(id)
			.then((response) => context.commit('testimonial', response.data))
			.catch(error => toast.add(error.message, 'error')),

	deleteTestimonial: (context, id) => {
		api.testimonials.delete(id)
			.then((response) => {
				context.commit('testimonial', response.data)
				console.log('sdf');
				this.$router.push({name: 'Testimonials'})
			})
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
