<template>
	<v-card class="mb-10">
		<v-card-title>
			<v-row align-content="center">
				<v-col cols="auto" class="d-none d-md-block">
					<v-sheet v-if="icon" :color="color" elevation="4" class="card-icon-title d-inline-block rounded mt-n9" :class="sheetClass">
						<v-icon dark :style="fontSize">{{ icon }}</v-icon>
					</v-sheet>
				</v-col>
				<v-col>
					<v-row justify="space-between" class="d-flex" style="align-items: center">
						<v-col cols="auto" class="">
							<h1 class="font-weight-light text-h3">
								<slot name="title"></slot>
							</h1>
						</v-col>
						<v-col cols="auto" class="d-flex" style="align-items: center">
							<slot name="title-actions-shown">
							</slot>
							<slot name="title-actions-hidden" v-if="toggle">
							</slot>
							<v-btn @click="toggle = !toggle" color="secondary" icon v-if="hasHiddenActions">
								<v-icon v-if="toggle">
									{{ $icons.close }}
								</v-icon>
								<v-icon v-else>
									{{ $icons.dots }}
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-title>

		<v-card-text>
			<slot></slot>
		</v-card-text>

		<v-card-actions>
			<slot name="footer-actions"></slot>
		</v-card-actions>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: 'TiCard',
	props: {
		color: {
			type: String,
			default: 'primary'
		},
		iconSmall: {
			type: Boolean,
			default: false,
		},
		icon: String,
	},
	computed: {
		hasHiddenActions() {
			return !!this.$slots['title-actions-hidden']
		},
		fontSize(){
			return this.iconSmall ? 'font-size: 16px' : 'font-size: 36px'
		},
		sheetClass(){
			return this.iconSmall ? 'pa-2' : 'pa-6'
		}
	},
	data: () => ({
		toggle: false
	})
}
</script>
