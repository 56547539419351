// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

import {
	layout,
	route,
} from '../util/routes'

Vue.use(Router)

const router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (to.hash) return {selector: to.hash}
		if (savedPosition) return savedPosition

		return {x: 0, y: 0}
	},
	routes: [
		layout('Minimal', [
			// Authentication
			route('Login', 'login', 'auth/Login', {
				pageTitle: 'Log in',
				publicRoute: true
			}),
			route('Logout', 'logout', 'auth/Logout', {
				pageTitle: 'Log out',
				publicRoute: true
			}),

		]),
		layout('Default', [
			route('Dashboard', 'dashboard', 'dashboard/Dashboard', {pageTitle: 'Dashboard'}),

			// Users
			route('Users', 'users', 'users/Index', {pageTitle: 'Users'}),
			route('UsersNew', 'users/new', 'users/Form', {pageTitle: 'User New', edit: false, warn: true}),
			route('UsersEdit', 'users/edit/:id', 'users/Form', {pageTitle: 'User Edit', edit: true, warn: true}, true),

			// Brands
			route('Brands', 'brands', 'brands/Index', {pageTitle: 'Brands'}),
			route('BrandsNew', 'brands/new', 'brands/Form', {pageTitle: 'Brands - New', edit: false, warn: true}),
			route('BrandsEdit', 'brands/edit/:id', 'brands/Form', {pageTitle: 'Brands - Edit', edit: true, warn: true }, true),

			// Benchmark Questions
			route('BenchmarkQuestions', 'benchmark_questions', 'benchmark_questions/Index', {pageTitle: 'BenchmarkQuestions'}),
			route('BenchmarkQuestionsNew', 'benchmark_questions/new', 'benchmark_questions/Form', {pageTitle: 'BenchmarkQuestions - New', edit: false, warn: true}),
			route('BenchmarkQuestionsEdit', 'benchmark_questions/edit/:id', 'benchmark_questions/Form', {pageTitle: 'BenchmarkQuestions - Edit', edit: true, warn: true}, true),

			// Articles
			route('Articles', 'articles', 'resources/articles/Index', {pageTitle: 'Articles', resourceType: 'article'}),
			route('ArticlesNew', 'articles/new', 'resources/articles/Form', {pageTitle: 'Articles - New', edit: false, resourceType: 'article', warn: true}),
			route('ArticlesEdit', 'articles/edit/:id', 'resources/articles/Form', {pageTitle: 'Articles - Edit', edit: true, resourceType: 'article'}, true),

			// Courses
			route('Courses', 'courses', 'resources/courses/Index', {pageTitle: 'Courses', resourceType: 'course'}),
			route('CoursesNew', 'courses/new', 'resources/courses/Form', {pageTitle: 'Courses - New', edit: false, resourceType: 'course', warn: true}),
			route('CoursesEdit', 'courses/edit/:id', 'resources/courses/Form', {pageTitle: 'Courses - Edit', edit: true, resourceType: 'article', warn: true}, true),

			// Podcasts
			route('Podcasts', 'podcasts', 'resources/podcasts/Index', {pageTitle: 'Podcasts', resourceType: 'podcast'}),
			route('PodcastsNew', 'podcasts/new', 'resources/podcasts/Form', {pageTitle: 'Podcasts - New', edit: false, resourceType: 'podcast', warn: true}),
			route('PodcastsEdit', 'podcasts/edit/:id', 'resources/podcasts/Form', {pageTitle: 'Podcasts - Edit', edit: true, resourceType: 'podcast', warn: true}, true),

			// Videos
			route('Videos', 'videos', 'resources/videos/Index', {pageTitle: 'Videos', resourceType: 'video'}),
			route('VideosNew', 'videos/new', 'resources/videos/Form', {pageTitle: 'Videos - New', edit: false, resourceType: 'video', warn: true}),
			route('VideosEdit', 'videos/edit/:id', 'resources/videos/Form', {pageTitle: 'Videos - Edit', edit: true, resourceType: 'video', warn: true}, true),

			// External Resources
			route('ExternalResources', 'external_resources', 'resources/external_resources/Index', {pageTitle: 'External Resources', resourceType: 'external_resource'}),
			route('ExternalResourcesNew', 'external_resources/new', 'resources/external_resources/Form', {pageTitle: 'External Resources - New', edit: false, resourceType: 'external_resource', warn: true}),
			route('ExternalResourcesEdit', 'external_resources/edit/:id', 'resources/external_resources/Form', {pageTitle: 'External Resources - Edit', edit: true, resourceType: 'external_resource', warn: true}, true),

			// Infographics
			route('Infographics', 'infographics', 'resources/infographics/Index', {pageTitle: 'Infographics', resourceType: 'infographic'}),
			route('InfographicsNew', 'infographics/new', 'resources/infographics/Form', {pageTitle: 'Infographics - New', edit: false, resourceType: 'infographic', warn: true}),
			route('InfographicsEdit', 'infographics/edit/:id', 'resources/infographics/Form', {pageTitle: 'Infographics - Edit', edit: true, resourceType: 'infographic', warn: true}, true),

			// Intermissions
			route('Intermissions', 'intermissions', 'resources/intermissions/Index', {pageTitle: 'Intermissions', resourceType: 'intermission'}),
			route('IntermissionsNew', 'intermissions/new', 'resources/intermissions/Form', {pageTitle: 'Intermissions - New', edit: false, resourceType: 'intermission', warn: true}),
			route('IntermissionsEdit', 'intermissions/edit/:id', 'resources/intermissions/Form', {pageTitle: 'Intermissions - Edit', edit: true, resourceType: 'intermission', warn: true}, true),

			// Case studies
			route('Case_studies', 'case_studies', 'resources/case_studies/Index', {pageTitle: 'CaseStudies', resourceType: 'case_study'}),
			route('Case_studiesNew', 'case_studies/new', 'resources/case_studies/Form', {pageTitle: 'CaseStudies - New', edit: false, resourceType: 'case_study', warn: true}),
			route('Case_studiesEdit', 'case_studies/edit/:id', 'resources/case_studies/Form', {pageTitle: 'CaseStudies - Edit', edit: true, resourceType: 'case_study', warn: true}, true),

			// Tracks
			route('Tracks', 'tracks', 'tracks/Index', {pageTitle: 'Tracks'}),
			route('TracksNew', 'tracks/new', 'tracks/Form', {pageTitle: 'Tracks - New', edit: false, warn: true}),

			// Certificates
			route('Certificates', 'certificates', 'certifications/certificates/Index', {pageTitle: 'Certificates'}),
			route('CertificatesNew', 'certificates/new', 'certifications/certificates/Form', {pageTitle: 'Certificates - New', edit: false, warn: true}),
			route('CertificatesEdit', 'certificates/edit/:id', 'certifications/certificates/Form', {pageTitle: 'Certificates - Edit', edit: true, warn: true}, true),

			// Diplomas
			route('Diplomas', 'diplomas', 'certifications/diplomas/Index', {pageTitle: 'Diplomas'}),
			route('DiplomasNew', 'diplomas/new', 'certifications/diplomas/Form', {pageTitle: 'Diplomas - New', edit: false, warn: true}),
			route('DiplomasEdit', 'diplomas/edit/:id', 'certifications/diplomas/Form', {pageTitle: 'Diplomas - Edit', edit: true, warn: true}, true),

			// Topics
			route('Topics', 'topics', 'topics/Index', {pageTitle: 'Topics'}),
			route('TopicsNew', 'topics/new', 'topics/Form', {pageTitle: 'Topics - New', edit: false, warn: true}),
			route('TopicsEdit', 'topics/edit/:id', 'topics/Form', {pageTitle: 'Topics - Edit', edit: true, warn: true}, true),

			// Training
			route('Training', 'training', 'training/Index', {pageTitle: 'Training'}),
			route('TrainingNew', 'training/new', 'training/Form', {pageTitle: 'Training - New', edit: false, warn: true}),
			route('TrainingEdit', 'training/edit/:id', 'training/Form', {pageTitle: 'Training - Edit', edit: true, warn: true}, true),

			// Xapi Contents
			route('XapiContents', 'xapi_contents', 'xapi_contents/Index', {pageTitle: 'XapiContents'}),
			route('XapiContentsNew', 'xapi_contents/new', 'xapi_contents/Form', {pageTitle: 'XapiContents - New', edit: false, warn: true}),
			route('XapiContentsEdit', 'xapi_contents/edit/:id', 'xapi_contents/Form', {pageTitle: 'XapiContents - Edit', edit: true, warn: true}, true),

			// Redirects
			route('Redirects', 'redirects', 'redirects/Index', {pageTitle: 'Redirects'}),
			route('RedirectsNew', 'redirects/new', 'redirects/Form', {pageTitle: 'Redirects - New', edit: false, warn: true}),
			route('RedirectsEdit', 'redirects/edit/:id', 'redirects/Form', {pageTitle: 'Redirects - Edit', edit: true, warn: true}, true),

			// Team members
			route('TeamMembers', 'team_members', 'team_members/Index', {pageTitle: 'TeamMembers'}),
			route('TeamMembersNew', 'team_members/new', 'team_members/Form', {pageTitle: 'TeamMembers - New', edit: false, warn: true}),
			route('TeamMembersEdit', 'team_members/edit/:id', 'team_members/Form', {pageTitle: 'TeamMembers - Edit', edit: true, warn: true}, true),

			// Corporates
			route('Corporates', 'corporates', 'corporates/Index', {pageTitle: 'Corporates'}),
			route('CorporatesNew', 'corporates/new', 'corporates/Form', {pageTitle: 'Corporates - New', edit: false, warn: true}),
			route('CorporatesEdit', 'corporates/edit/:id', 'corporates/Form', {pageTitle: 'Corporates - Edit', edit: true, warn: true}, true),

			// Testimonials
			route('Testimonials', 'testimonials', 'testimonials/Index', {pageTitle: 'Testimonials'}),
			route('TestimonialsNew', 'testimonials/new', 'testimonials/Form', {pageTitle: 'Testimonials - New', edit: false, warn: true}),
			route('TestimonialsEdit', 'testimonials/edit/:id', 'testimonials/Form', {pageTitle: 'Testimonials - Edit', edit: true, warn: true}, true),

			// Documents
			route('Documents', 'documents', 'documents/Index', {pageTitle: 'Documents'}),
			route('DocumentsNew', 'documents/new', 'documents/Form', {pageTitle: 'Documents - New', edit: false, warn: true}),
			route('DocumentsEdit', 'documents/edit/:id', 'documents/Form', {pageTitle: 'Documents - Edit', edit: true, warn: true}, true),

			// Reports
			route('Reports', 'reports', 'reports/Index', {pageTitle: 'Reports'}),
			route('ReportsBenchmarkResults', 'reports/benchmark_results', 'reports/BenchmarkResults', {pageTitle: 'Benchmark Results - Report'}, true),
			route('ReportsBenchmarkUserResults', 'reports/benchmark_user_results', 'reports/BenchmarkUserResults', {pageTitle: 'Benchmark Results - Report'}, true),
			route('ReportsUserActivity', 'reports/user_activity', 'reports/UserActivity', {pageTitle: 'User Activity - Report'}, true),
			route('ReportsCertificationProgress', 'reports/certificate_progress', 'reports/CertificateProgress', {pageTitle: 'Certificate Progress - Report'}, true),
			route('ReportsSelfAssessments', 'reports/self_assessments', 'reports/SelfAssessments', {pageTitle: 'Self Assessments - Report'}, true),
			route('ReportsBenchmarkComparison', 'reports/benchmark_comparison', 'reports/BenchmarkComparison', {pageTitle: 'Benchmark Comparison - Report'}, true),
			route('ReportsBenchmarkComparisonCombined', 'reports/benchmark_comparison_combined', 'reports/BenchmarkComparisonCombined', {pageTitle: 'Benchmark Comparison Combined - Report'}, true),

			// Podcast reviews
			route('PodcastReviews', 'podcast_reviews', 'podcast_reviews/Index', {pageTitle: 'Podcast Reviews'}),

			// Settings
			route('Settings', 'settings', 'settings/Index', {pageTitle: 'Settings'}),
			route('SettingsEdit', 'settings/edit/:id', 'settings/Form', {pageTitle: 'Settings - Edit', edit: true, warn: true}, true),

			// Podcast Awards
			route('PodcastAwards', 'podcast_awards', 'podcast_awards/Index', {pageTitle: 'Podcast Awards'}),
			route('PodcastAwardsEdit', 'podcast_awards/edit/:id', 'podcast_awards/Form', {pageTitle: 'Podcast Awards - Edit', edit: true}, true),

			// Podcast Categories
			route('PodcastAwardCategories', 'podcast_award_categories', 'podcast_award_categories/Index', {pageTitle: 'Podcast Award Category'}),
			route('PodcastAwardCategoriesNew', 'podcast_award_categories/new', 'podcast_award_categories/Form', {pageTitle: 'Podcast Award Category - New', edit: false, warn: true}),
			route('PodcastAwardCategoriesEdit', 'podcast_award_categories/edit/:id', 'podcast_award_categories/Form', {pageTitle: 'Podcast Award Category - Edit', edit: true, warn: true}, true),
		]),
	],
})

router.beforeEach((to, from, next) => {

	let signedIn = store.get('user/token')
	let requiresAuth = !to.meta.publicRoute

	if (from.meta.warn && !from.meta.disableWarn) {
		let input = confirm("You are about to leave this page \nAny unsave changes will be lost.");
		if (!input) return;
	}
	// turn back on warnings if they were turned off by save button
	from.meta.disableWarn = false;

	// redirect / to dashboard
	if (to.path === '' || to.path === '/') return next({name: 'Dashboard'})

	if (!signedIn && requiresAuth) return next({name: 'Login'})
	return next()
	// return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
