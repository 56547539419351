const models = {
	Resource(type) {
		return {
			id: null,
			type: type,
			title: '',
			subtitle: '',
			caption: '',
			summary: null,
			audio_link: null,
			minutes: 0,
			meta_title: '',
			meta_description: '',
			meta_keywords: [],
			featured_image_id: null,
			audio_transcript_url: null,
			text_transcript_url: null,
			expertise: null,
			status: null,
			published_date: null,
			updated_date: null,
			premium: false,
			premium_until: null,
			slug: '',
			show_on_website: true,
			xapi_content_id: null,
			spotify: null,
			apple: null,
			google: null,
			amazon: null,
			audible: null,
			deezer: null,
			castbox: null,
			rss: null,
			youtube: null,
			resource_tags: '',
			mp4_link: '',
			topics_attributes: [],
			url_redirects_attributes: [],
			related_resources_attributes: [],
			documents_attributes: [],
			resource_content_attributes: {
				content: null,
				index_card_title: null,
				index_card_content: null,
				index_card_position: null,
				cta_enabled: false,
				cta_text: null,
				cta_btn_text: null,
				cta_btn_link: null,
				cta_bg_color: null,
				cta_shape_color_1: null,
				cta_shape_color_2: null,
			},
			external_link: null,
		}
	},
	UrlRedirect() {
		return {
			id: null,
			redirect_from: '',
			redirect_to: '',
			_destroy: false,
		}
	},
	Setting() {
		return {
			id: null,
			setting_name: '',
			setting_value: '',
			setting_description: '',
			group: '',
			setting_type: '',
			setting_options: '',
			_destroy: false,
		}
	},
	Track() {
		return {
			id: null,
			brand_id: null,
			name: '',
			expertise: null,
			track_resources_attributes: [
				{
					id: null,
					track_id: null,
					resource_id: null,
					order: null,
					resource: {
						title: '',
					},
					_destroy: false,
				}
			],
		}
	},
	Certification(type) {
		return {
			id: null,
			type: type,
			name: null,
			internal_name: null,
			corporate_owner: null,
			completion_percent: 100,
			is_cim: false,
			description: null,
			featured_image_id: null,
			share_image_id: null,
			show_benchmark_quiz: false,
			pathways: [],
			certification_resources_attributes: [
				{
					id: null,
					resource_id: null,
					order: 0,
					section: '',
					_destroy: false
				}
			],
			certification_submission_dates_attributes: [
				{
					id: null,
					expression_of_interest: null,
					full_date: null,
					final_draft: null,
					cim_submission: null,
					_destroy: false
				}
			]
		}
	},
	CertificationSubmissionDate() {
		return {
			id: null,
			expression_of_interest: null,
			full_plan: null,
			final_draft: null,
			cim_submission: null,
			_destroy: false
		}

	},
	XapiContent() {
		return {
			id: null,
			name: '',
			description: '',
			brand_id: null,
			file: null,
			courses: []
		}
	},
	XapiStatement() {
		return {
			id: null,
			user_id: null,
			resource_id: null,
			resource_title: null,
			resource_brand: null,
			resource_brand_id: null,
			resource_type: null,
			session: null,
			activity: null,
			score_points: null,
			pass_points: 3,
			score_percent: null,
			pass_percent: 70,
			passed: false,
			created_at: null,
		}
	},
	TeamMember() {
		return {
			id: null,
			name: '',
			title: '',
			bio: '',
			bio_short: '',
			image_id: null,
			quote: '',
			quote_by: '',
			quote_logo_id: null,
			order: 99
		}
	},
	RelatedResource() {
		return {
			id: null,
			related_resource_id: null,
		}
	},
	Testimonial() {
		return {
			id: null,
			content: null,
			author: null,
			author_title: null,
			author_company: null,
		}
	},
	User() {
		return {
			id: null,
			corporate_id: null,
			logo_id: null,
			email: '',
			first_name: '',
			last_name: '',
			alias: '',
			last_login_at: null,
			login_locked_until: null,
			is_active: true,
			membership_status: null,
			type: 'Customer',
			stripe_customer_id: '',
			organization: '',
			job_title: '',
			country: '',
			interested_cim: false,
			landing_page: null,
			password_reset_flag: false,
			corporate_custom_fields: [],
			override_subscription: false,
			override_subscription_until: null,
			cim_term_minimum_skills: false,
			cim_term_fee: false,
			cim_term_membership: false,
			cim_membership_number: null,
			cim_term_special_needs: false,
			cim_term_timescale: false,
			created_at: null,
			test_user: false,
			legacy_paypal: false,
			is_corporate_admin: false,
			xapi_statement_attributes: [],
			benchmark_results: []
		}
	},
	Corporate() {
		return {
			name: '',
			user_limit: 0,
			slug: '',
			logo: '',
			azure_sso: false,
			show_password_login: true,
			accent_1: '#26262A',
			accent_2: '#DB6D3B',
			title_font: null,
			title_font_source: null,
			body_font: null,
			body_font_source: null,
			hide_shapes: false,
			welcome_message: null,
			welcome_text: null,
			short_name: null,
			technical_contact: null,
			technical_email: null,
			corporate_contact: null,
			corporate_email: null,
			expertise_1_alias: null,
			expertise_2_alias: null,
			expertise_3_alias: null,
			show_toolkit: false,
			show_suggested_content: false,
			show_new_resources: true,
			show_updated_resources: true,
			show_learning_hub_resources: true,
			show_courses: true,
			show_benchmark: true,
			show_resources: true,
			show_cim_certification: false,
			show_cim_qualification: false,
			show_reports: false,
			show_corporate_reports: false,
			filter_resource_types: [],
			users: [],
			corporate_allowed_domains_attributes: [],
			corporate_allowed_emails_attributes: [],
			open_registration: false,
			corporate_custom_fields_attributes: [],
			documents_attributes: [],
			corporate_user_invites_attributes: [],
			invite_subject: 'Invitation to Target Internet',
			invite_text: 'You have been invited to join Target Internet. Please click below to accept the invitation.',
			corporate_certifications_attributes: [],
			corporate_status: 1,
			landing_page: null,
			use_ti_toolkit: false,
			filter_key_focus: [],
			filter_seniority: [],
			filter_industry: [],
			benchmark_report_filters: [],
			use_user_groups: false,
		}
	},
	Document() {
		return {
			id: null,
			filename: '',
			file: '',
			slug: null,
			_destroy: false
		}
	},
	BenchmarkQuestion() {
		return {
			id: null,
			topic_id: null,
			question: null,
			option_1: null,
			option_2: null,
			option_3: null,
			option_4: null,
			answer: null,
			weight: null,
			expertise: null,
		}
	},
	Topic() {
		return {
			id: null,
			name: null,
			use_for_benchmark: false,
			use_for_resources: false,
			hide_from_footer: false,
			long_name: null,
		}
	},
	Brand() {
		return {
			id: null,
			name: null,
			image: null,
			image_id: null
		}
	},
	PodcastAwardCategory() {
		return {
			id: null,
			name: null,
			podcast_award_questions_attributes: [
				models.PodcastAwardQuestion()
			]
		}
	},
	PodcastAwardQuestion() {
		return {
			id: null,
			question_type: null,
			question: null,
			options: null,
			_destroy: false,
		}
	},
	TrainingCourse() {
		return {
			id: null,
			title: null,
			active: false,
			slug: null,
			caption: null,
			subtitle: null,
			section_1_title: null,
			section_1_text: null,
			section_2_title: null,
			section_2_subtitle: null,
			section_2_text: null,
			section_2_list: null,
		}
	},
	PodcastAward() {
		return {
			id: null,
			year: null,
			ranks: [
				{
					id: null,
					status: 'Nomination',
					product: {
						id: null,
						name: null,
						url: null,
					},
					nominations: [
						{
							id: null,
							answers: [],
							nominator_first_name: null,
							nominator_last_name: null,
							nominator_email: null,
							keep_informed: false,
							marketing_consent: false,
						}
					]
				}
			],
		}
	},
}

export default models;