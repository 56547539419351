// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	benchmark_questions:[],
	benchmark_question: {
		users: []
	},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getBenchmark_questions: ({ dispatch }) => {
		api.benchmark_questions.index()
			.then(response => dispatch('setBenchmark_questions', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getBenchmark_question: ({ dispatch }, id) => {
		api.benchmark_questions.show(id)
			.then(response => dispatch('setBenchmark_question', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
