<template>
<!--	<v-switch-->
<!--		v-model="production"-->
<!--		@change="onChange"-->
<!--		:label="production === true ? 'Live' : 'Staging'"-->
<!--	></v-switch>-->
	<v-select
		class="mt-8"
		v-model="selectedEnvironment"
		:items="environments"
		label="Environment"
		@change="onChange"
		:solo="solo"
	></v-select>
</template>

<script>
export default {
	name: "EnvSwitch",
	props:{
		solo:{
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		environments: ['Live', 'Staging', 'Local'],
		selectedEnvironment: 'Staging'
	}),
	mounted(){
		this.selectedEnvironment = localStorage.getItem('env') || 'Staging'
	},
	methods: {
		onChange(value){
			localStorage.setItem('env', value);
			this.selectedEnvironment = value;
			window.location.reload();
		}
	}

}
</script>