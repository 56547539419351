// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';
import vue from "vue";

// Data
const state = {
	brands:[],
	brand: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getBrands: ({ dispatch }) => {
		api.brands.index()
			.then(response => dispatch('setBrands', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getBrand: ({ dispatch }, id) => {
		api.brands.show(id)
			.then(response => dispatch('setBrand', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
