<template>
	<div>
		<svg v-if="variant === 'square'" class="square" :style="style" viewBox="0 0 24 24">
			<path fill="currentColor" d="M3,3V21H21V3"/>
		</svg>

		<svg v-if="variant === 'circle'" class="circle" :style="style" viewBox="0 0 24 24">
			<path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
		</svg>

		<svg v-if="variant === 'triangle'" class="triangle" :style="style" viewBox="0 0 24 24">
			<path fill="currentColor" d="M1,21H23L12,2" />
		</svg>

		<svg v-if="variant === 'tear'" class="tear" :style="style" viewBox="0 0 24 24">
<!--			<path fill="currentColor" d="M167.02,0h167.02V167.02c0,92.18-74.84,167.02-167.02,167.02h0C74.84,334.03,0,259.2,0,167.02H0C0,74.84,74.84,0,167.02,0Z"/>-->
<!--			<path fill="currentColor" d="M1,21H23L12,2" />-->
			<path fill="currentColor" class="cls-1" d="M334.03,0h0V334.03H0C0,149.68,149.68,0,334.03,0Z"/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "TiShape",
	props: {
		variant: {
			type: String,
			default: "circle"
		},
		size: {
			type: [Number, String],
			default: 300
		},
		color: {
			type: String,
			default: "#eac2ce",
		},
		location: {
			type: String,
			default: "bottom-left",
			validator(value) {
				return ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(value)
			}
		},
		hide: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		computedSize() {
			return typeof this.size === "string" ? this.size : this.size + "px";
		},
		computedHide(){
			return false
		},
		style() {
			return {
				width: this.computedSize,
				height: this.computedSize,
				color: this.color,
				transform: this.rotation(),
				top: this.location.includes("top") ? this.offset : "unset",
				bottom: this.location.includes("bottom") ? this.offset : "unset",
				left: this.location.includes("left") ? this.offset : "unset",
				right: this.location.includes("right") ? this.offset : "unset",
				opacity: this.computedHide ? 0 : 1
			};
		},
		offset() {
			return `-${this.size / 1.75}px`;
		},
	},
	mounted() {
		this.addRelativePositionToParent();
	},
	methods: {
		rotation() {
			if (this.location === "top-left") {
				return this.variant === 'triangle' ? 'rotate(315deg) scaleX(1)' : 'rotate(150deg)';
			}
			if (this.location === "top-right") {
				return this.variant === 'triangle' ? 'rotate(45deg) scaleX(-1)' : 'rotate(120deg)';
			}
			if (this.location === "bottom-left") {
				return this.variant === 'triangle' ? 'rotate(230deg) scaleX(-1)' : 'rotate(60deg)';
			}
			if (this.location === "bottom-right") {
				return this.variant === 'triangle' ? 'rotate(135deg) scaleX(-1)' : 'rotate(120deg)';
			}
		},
		addRelativePositionToParent() {
			const parentElement = this.$el.parentElement;

			if (parentElement) {
				parentElement.style.position = 'relative';
				parentElement.style.overflow = 'hidden';
			}
		},
	}
}
</script>

<style lang="scss">

.shape {
	position: absolute;
	transition: opacity 150ms ease;
}

.circle {
	@extend .shape;
	border-radius: 100%;
}

.square {
	@extend .shape;
}
.tear {
	@extend .shape;
}

.triangle {
	@extend .shape;
	width: 0;
	height: 0;
}
</style>