import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import './filters'
import api from "./services/api";
import icons from "./util/icons";
import store from './store'
import {sync} from 'vuex-router-sync'
import {toast} from "./util/helpers";
import "./mixins/loading"
// import VueJsonToCsv from 'vue-json-to-csv'
// Vue.component('VueJsonToCsv', VueJsonToCsv)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$icons = icons
Vue.prototype.$toast = toast

sync(store, router)

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App),
}).$mount('#app')
