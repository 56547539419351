<template>
	<div>
		<p>Google preview</p>
		<v-card>
			<v-card-text>
				<p class="mx-0 mt-0 mb-2" style="color: #202124; font-family: Arial, sans-serif; font-size: 14px;">https://targetinternet.com/{{ urlPrefix}}/{{ url }}</p>
				<h3 class="mx-0 mt-0 mb-2" style="color: #1a0dab; font-family: Arial, sans-serif; font-size: 20px; font-weight: normal">{{ metaTitle }} | Target Internet</h3>
				<p class="ma-0" style="color: #4d5156; font-family: Arial, sans-serif; font-size: 14px;">{{ metaDescription }}</p>
			</v-card-text>
		</v-card>
	</div>

</template>

<script>
export default {
	name: "TiGooglePreview",
	props: {
		url: {
			type: String,
			default:'',
			required: true
		},
		urlPrefix: {
			type: String,
			default:'',
			required: true
		},
		metaTitle: {
			type: String,
			default:'',
			required: true
		},
		metaDescription: {
			type: String,
			default:'',
			required: true
		}
	}
}
</script>

<style scoped>

</style>