// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	xapi_contents: [],
	xapi_content: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getXapi_contents: ({dispatch}) => {
		// return new Promise((resolve, reject) => {
			api.xapi_contents.index()
				.then(response => {
					dispatch('setXapi_contents', response.data)
					// resolve()
				})
				.catch(error => {
					console.log(error);
					toast.add(error.message, 'error')
					// reject()
				})
		// })


	},
	getXapi_content: ({dispatch}, id) => {
		api.xapi_contents.show(id)
			.then(response => dispatch('setXapi_content', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
