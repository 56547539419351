<template>
	<div v-if="toast" class="toast">
		<v-alert app :type="toast.type">
			<template #close>
				<v-icon class="ml-3" @click="removeAlert">mdi-close</v-icon>
			</template>
			<span>{{ toast.message }}</span>
		</v-alert>
	</div>
</template>

<script>
import {get} from 'vuex-pathify'

export default {
	name: "TiToast",
	props: {
		type: {
			type: String,
			default: 'info'
		}
	},
	data: () => ({
		toastTimer: null
	}),
	watch: {
		toast() {
			if (this.toastTimer) return
			this.toastTimer = setTimeout(() => {
				this.removeAlertViaTimer()
			}, 5000)
		}
	},
	computed: {
		toast: get('app/toasts@0')
	},
	methods: {
		removeAlert() {
			this.$toast.remove()
		},
		removeAlertViaTimer() {
			this.removeAlert()
			clearTimeout(this.toastTimer)
			this.toastTimer = null
		}
	}
}
</script>

<style lang="scss">
.toast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	text-align: center;

	& > .v-alert {
		display: inline-block;
	}
}
</style>