// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	videos:[],
	video: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getVideos: ({ dispatch }) => {
		api.videos.index()
			.then(response => dispatch('setVideos', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getVideo: ({ dispatch }, id) => {
		api.videos.show(id)
			.then(response => dispatch('setVideo', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
