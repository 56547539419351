// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	team_members:[],
	team_member: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getTeam_members: ({ dispatch }) => {
		api.team_members.index()
			.then(response => dispatch('setTeam_members', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getTeam_member: ({ dispatch }, id) => {
		api.team_members.show(id)
			.then(response => dispatch('setTeam_member', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
