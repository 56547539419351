<template>
	<div>
		<slot name="show">

		</slot>

		<slot name="toggle">
			<v-btn fab dark small color="error" @click="destroy">
				<v-icon>{{ $icons.delete }}</v-icon>
			</v-btn>
		</slot>

		<v-btn @click="toggle = !toggle" color="secondary" icon>
			<v-icon v-if="toggle">
				{{ $icons.close }}
			</v-icon>
			<v-icon v-else>
				{{ $icons.dots }}
			</v-icon>
		</v-btn>

	</div>
	<!--	<v-speed-dial v-model="fab" direction="bottom" transition="slide-y-reverse-transition">-->
	<!--		<template v-slot:activator>-->
	<!--			<v-btn v-model="fab" color="secondary" icon>-->
	<!--				<v-icon v-if="fab">-->
	<!--					{{ $icons.close }}-->
	<!--				</v-icon>-->
	<!--				<v-icon v-else>-->
	<!--					{{ $icons.dots }}-->
	<!--				</v-icon>-->
	<!--			</v-btn>-->
	<!--		</template>-->
	<!--		<slot>-->

	<!--		</slot>-->
	<!--	</v-speed-dial>-->
</template>

<script>
export default {
	name: "TiDial",
	data: () => ({
		toggle: false
	})
}
</script>

<style scoped>

</style>