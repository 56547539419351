// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	images:[],
	image: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
