// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';
import Models from "../../models";

// Data
const state = {
	case_studies:[],
	case_study: Models.Resource('CaseStudy'),
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getCase_studies: ({ dispatch }) => {
		api.case_studies.index()
			.then(response => dispatch('setCase_studies', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getCase_study: ({ dispatch }, id) => {
		api.case_studies.show(id)
			.then(response => dispatch('setCase_study', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
