<template>
	<v-menu
		v-model="dialog"
		:close-on-content-click="false"
		:nudge-right="40"
		transition="scale-transition"
		offset-y
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="date"
				:label="label"
				prepend-icon="mdi-calendar"
				readonly
				v-bind="attrs"
				v-on="on"
				:append-icon="$icons.close"
				@click:append="update(null)"
				:hint="hint"
				:persistent-hint="persistentHint"
			></v-text-field>

		</template>
		<v-date-picker
			:value="value"
			@input="update"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: "TiDatePicker",
	props: {
		value: {
			required: true
		},
		label: {
			type: String,
			default: 'Date'
		},
		hint: {
			type: String,
		},
		persistentHint: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dialog: false,
		date: null,
	}),
	watch: {
		value(val) {
			this.date = val
		},
	},
	mounted() {
		this.date = this.value
	},
	methods: {
		update(newValue) {
			this.date = newValue
			this.$emit('input', newValue);
			this.dialog = false
		},
	}
}
</script>