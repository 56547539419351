<template>
	<vue-json-to-csv :json-data="jsonData" :csv-title="filename">
		<v-btn class="primary--text" elevation="0" fab :disabled="jsonData.length < 1" small>
			<v-icon>{{ $icons.csv }}</v-icon>
		</v-btn>
	</vue-json-to-csv>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'

export default {
	name: "TiExportToCsv",
	props: {
		jsonData: {
			type: Array,
			required: true,
			default: []
		},
		filename: {
			default: 'export'
		}
	},
	components: {
		VueJsonToCsv
	}
}
</script>

<style scoped>

</style>