<template>
	<vue-editor
		id="editor"
		:value="value"
		@input="update"
		useCustomImageHandler
		@image-added="handleImageAdded"
	></vue-editor>
</template>

<script>
import {VueEditor} from "vue2-editor";

export default {
	name: "TiEditorNew",
	props: ['value'],
	components: {
		VueEditor
	},
	methods: {
		update(newValue) {
			this.$emit('input', newValue);
		},
		handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
			let form = new FormData
			form.append('image[image]', file)
			this.$api.images.create(form)
				.then(response => {
					let baseUrl = localStorage.getItem('env') === 'Local' ? 'http://localhost:3000' : ''
					let imageUrl = `${baseUrl}${response.data.medium}`
					Editor.insertEmbed(cursorLocation, "image", imageUrl);
					resetUploader();
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error')
				})
		}
	}
}
</script>