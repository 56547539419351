// Pathify
import {make} from 'vuex-pathify'
import {toast} from '@/util/helpers';

// Data
const state = {
	user: {},
	token: '',
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	logout({commit}) {
		commit('user', {})
		commit('token', null)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
