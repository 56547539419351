// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	topics:[],
	topic: {},
}

const getters = {
	...make.getters(state),
	forResources: (state, getters) => {
		return getters.topics.filter((t) => t.use_for_resources)
	},
	forBenchmark: (state, getters) => {
		return getters.topics.filter((t) => t.use_for_benchmark)
	},
}


const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getTopics: ({ dispatch }) => {
		api.topics.index()
			.then(response => dispatch('setTopics', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getTopic: ({ dispatch }, id) => {
		api.topics.show(id)
			.then(response => dispatch('setTopic', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
