// Icons
import icons from '@/util/icons'

// Pathify
import {make} from 'vuex-pathify'

// Data
const state = {
	drawer: null,
	mini: false,
	loading: 0,
	toasts: [],
	items: [
		{
			title: 'Dashboard',
			icon: icons.dashboard,
			to: '/',
		},
		{
			title: 'Tracks',
			icon: icons.tracks,
			to: '/tracks'
		},
		{
			title: 'Certifications',
			icon: icons.certificates,
			items: [
				{
					title: 'Certificates',
					icon: icons.certificates,
					to: '/certificates/',
				},
				{
					title: 'Diplomas',
					icon: icons.diplomas,
					to: '/diplomas/',
				},
			]
		},
		{
			title: 'Resources',
			icon: icons.course,
			items: [
				{
					title: 'Courses',
					icon: icons.course,
					to: '/courses/',
				},
				{
					title: 'Xapi contents',
					icon: icons.xapi_contents,
					to: '/xapi_contents/',
				},
				{
					title: 'Podcasts',
					icon: icons.podcasts,
					to: '/podcasts/',
				},
				{
					title: 'Articles',
					icon: icons.articles,
					to: '/articles/',
				},
				{
					title: 'Case studies',
					icon: icons.case_studies,
					to: '/case_studies/',
				},
				{
					title: 'Videos',
					icon: icons.videos,
					to: '/videos/',
				},
				{
					title: 'Infographics',
					icon: icons.infographics,
					to: '/infographics/',
				},
				{
					title: 'External',
					icon: icons.external,
					to: '/external_resources/',
				},
				{
					title: 'Intermission',
					icon: icons.videos,
					to: '/intermissions/',
				},
			]
		},

		{
			title: 'Brands',
			icon: icons.brands,
			to: '/brands/',
		},
		{
			title: 'Topics',
			icon: icons.topics,
			to: '/topics/',
		},
		{
			title: 'Benchmark',
			icon: icons.corporates,
			to: '/benchmark_questions/',
		},
		{
			title: 'Testimonials',
			icon: icons.testimonials,
			to: '/testimonials/',
		},
		{
			title: 'Team members',
			icon: icons.users,
			to: '/team_members/',
		},
		{
			title: 'Corporates',
			icon: icons.corporates,
			to: '/corporates/',
		},
		{
			title: 'Users',
			icon: icons.users,
			to: '/users/',
		},
		{
			title: 'Redirects',
			icon: icons.redirects,
			to: '/redirects/',
		},
		{
			title: 'Documents',
			icon: icons.document,
			to: '/documents/',
		},
		{
			title: 'Training',
			icon: icons.training,
			to: '/training/',
		},
		{
			title: 'Reports',
			icon: icons.reports,
			to: '/reports/',
		},
		{
			title: 'Podcast awards',
			icon: icons.podcast_award,
			items: [
				{
					title: 'Awards',
					icon: icons.podcast_award,
					to: '/podcast_awards/',
				},
				{
					title: 'Podcast categories',
					icon: icons.c,
					to: '/podcast_award_categories/',
				},
			]
		},
		// {
		// 	title: 'Reviews (WIP)',
		// 	icon: icons.star,
		// 	to: '/podcast_reviews/',
		// },
		{
			title: 'Settings',
			icon: icons.settings,
			to: '/settings/',
		}
	],
}

const getters = {}

const mutations = {
	...make.mutations(state),
	addToast: (state, payload) => state.toasts.unshift(payload),
	removeToast: state => state.toasts.shift(),
}

const actions = {
	...make.actions(state),
	addLoading: ({dispatch, state}) => dispatch('setLoading', state.loading + 1),
	reduceLoading: ({dispatch, state}) => dispatch('setLoading', (state.loading - 1) < 0 ? 0 : state.loading - 1),
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
