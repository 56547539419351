<template>
	<v-fade-transition mode="out-in">
		<router-view/>
	</v-fade-transition>
</template>

<script>
// Styles
import '@/styles/overrides.sass'

export default {
	name: 'App',
	metaInfo: {
		title: 'Admin',
		titleTemplate: '%s | Target Internet',
		htmlAttrs: {lang: 'en'},
		meta: [
			{charset: 'utf-8'},
			{name: 'viewport', content: 'width=device-width, initial-scale=1'},
		],
	},
}
</script>

<style lang="scss">

.hover-pointer:hover {
	cursor: pointer;
}

.ql-tooltip {
	z-index: 99!important;
}
.theme--light.v-card > .v-card__text {
	color: #000!important;
}
</style>
