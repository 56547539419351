// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	user_key_focuses:[],
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getUserKeyFocuses: ({ dispatch }) => {
		api.user_key_focus.index()
			.then(response => dispatch('setUser_key_focuses', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
