import axios from 'axios'
// import env from '../env'
import store from '../store/index'

let env = localStorage.getItem('env') || 'Staging'
// let token = localStorage.getItem('token')
let baseURL;

switch (env) {
	case 'Local':
		baseURL = 'http://localhost:3000';
		break;
	case 'Staging':
		baseURL = 'https://staging.targetinternet.com';
		break;
	case 'Live':
		baseURL = 'https://targetinternet.com';
		break;
	default:
		baseURL = 'https://staging.targetinternet.com';
}
let apiVersion = 'v1';
axios.defaults.baseURL = `${baseURL}/api/${apiVersion}/`;

axios.defaults.headers = {
	'Content-Type': 'application/json',
	'Accept': 'application/json',
	'X-Api-Key': 'PGwhbR73TVVkNbVmX3FG9ZZWxHqTW5Vw',
};
axios.interceptors.request.use((config) => {
	store.dispatch('app/addLoading', null, {root: true})
	let token = store.getters['user/token']
	// config.headers.common = {...config.headers.common, 'Authorization': () => store.getters['app/authToken']}
	config.headers = {...config.headers, 'Authorization': token}
	return config;
}, function (error) {
	return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
	store.dispatch('app/reduceLoading', null, {root: true})
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	store.dispatch('app/reduceLoading', null, {root: true})
	// Do something with response error
	return Promise.reject(error);
});

export default {
	baseURL: baseURL,
	apiVersion: apiVersion,
	sessions: {
		create: (payload) => (axios.post('sessions', payload))
	},
	users: {
		index: (page, searchTerm) => (axios.get(`users?page=${page}&search_term=${searchTerm}`)),
		create: (user) => (axios.post('users', {user: user})),
		update: (id, user) => (axios.put(`users/${id}`, {user: user})),
		destroy: (id) => (axios.delete(`users/${id}`)),
		show: (id) => (axios.get(`users/${id}`)),
		reset_password: (id) => (axios.get(`users/${id}/reset_password`)),
	},
	images: {
		create: (image) => (axios.post('images', image)),
		update: (id, image) => (axios.put(`images/${id}`, {image: image})),
		delete: (id) => (axios.delete(`images/${id}`)),
		show: (id) => (axios.get(`images/${id}`)),
	},
	dashboard: {
		index: () => (axios.get('dashboard')),
	},
	documents: {
		index: () => (axios.get('documents')),
		create: (document) => (axios.post('documents', document)),
		update: (id, document) => (axios.put(`documents/${id}`, document)),
		delete: (id) => (axios.delete(`documents/${id}`)),
		show: (id) => (axios.get(`documents/${id}`)),
	},
	pages: {
		index: () => (axios.get('pages')),
		create: (page) => (axios.post('pages', {page: page})),
		update: (id, page) => (axios.put(`pages/${id}`, {page: page})),
		delete: (id) => (axios.delete(`pages/${id}`)),
		show: (id) => (axios.get(`pages/${id}`)),
	},
	benchmark_questions: {
		index: () => (axios.get('benchmark_questions')),
		create: (benchmark_question) => (axios.post('benchmark_questions', {benchmark_question: benchmark_question})),
		update: (id, benchmark_question) => (axios.put(`benchmark_questions/${id}`, {benchmark_question: benchmark_question})),
		delete: (id) => (axios.delete(`benchmark_questions/${id}`)),
		show: (id) => (axios.get(`benchmark_questions/${id}`)),
	},
	brands: {
		index: () => (axios.get('brands')),
		create: (brand) => (axios.post('brands', {brand: brand})),
		update: (id, brand) => (axios.put(`brands/${id}`, {brand: brand})),
		delete: (id) => (axios.delete(`brands/${id}`)),
		show: (id) => (axios.get(`brands/${id}`)),
	},
	resources: {
		index: () => (axios.get('resources')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	articles: {
		index: () => (axios.get('resources?filter_type=article')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	videos: {
		index: () => (axios.get('resources?filter_type=video')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	external_resources: {
		index: () => (axios.get('resources?filter_type=external_resource')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	infographics: {
		index: () => (axios.get('resources?filter_type=infographic')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	courses: {
		index: () => (axios.get('resources?filter_type=course')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	intermissions: {
		index: () => (axios.get('resources?filter_type=intermission')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	podcasts: {
		index: () => (axios.get('resources?filter_type=podcast')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	case_studies: {
		index: () => (axios.get('resources?filter_type=case_study')),
		create: (resource) => (axios.post('resources', {resource: resource})),
		update: (id, resource) => (axios.put(`resources/${id}`, {resource: resource})),
		delete: (id) => (axios.delete(`resources/${id}`)),
		show: (id) => (axios.get(`resources/${id}`)),
	},
	tracks: {
		index: () => (axios.get('tracks')),
		create: (track) => (axios.post('tracks', {track: track})),
		update: (id, track) => (axios.put(`tracks/${id}`, {track: track})),
		delete: (id) => (axios.delete(`tracks/${id}`)),
		show: (id) => (axios.get(`tracks/${id}`)),
	},
	certificates: {
		index: () => (axios.get('certifications?filter_type=certificate')),
		create: (certification) => (axios.post('certifications', {certification: certification})),
		update: (id, certification) => (axios.put(`certifications/${id}`, {certification: certification})),
		delete: (id) => (axios.delete(`certifications/${id}`)),
		show: (id) => (axios.get(`certifications/${id}`)),
	},
	diplomas: {
		index: () => (axios.get('certifications?filter_type=diploma')),
		create: (certification) => (axios.post('certifications', {certification: certification})),
		update: (id, certification) => (axios.put(`certifications/${id}`, {certification: certification})),
		delete: (id) => (axios.delete(`certifications/${id}`)),
		show: (id) => (axios.get(`certifications/${id}`)),
	},
	redirects: {
		index: () => (axios.get('url_redirects')),
		create: (url_redirect) => (axios.post('url_redirects', {url_redirect: url_redirect})),
		update: (id, url_redirect) => (axios.put(`url_redirects/${id}`, {url_redirect: url_redirect})),
		delete: (id) => (axios.delete(`url_redirects/${id}`)),
		show: (id) => (axios.get(`url_redirects/${id}`)),
	},
	topics: {
		index: () => (axios.get('topics')),
		create: (topic) => (axios.post('topics', {topic: topic})),
		update: (id, topic) => (axios.put(`topics/${id}`, {topic: topic})),
		delete: (id) => (axios.delete(`topics/${id}`)),
		show: (id) => (axios.get(`topics/${id}`)),
	},
	training_courses: {
		index: () => (axios.get('training_courses')),
		create: (training_course) => (axios.post('training_courses', {training_course: training_course})),
		update: (id, training_course) => (axios.put(`training_courses/${id}`, {training_course: training_course})),
		delete: (id) => (axios.delete(`training_courses/${id}`)),
		show: (id) => (axios.get(`training_courses/${id}`)),
	},
	xapi_contents: {
		index: () => (axios.get('xapi_contents')),
		create: (xapi_content) => (axios.post('xapi_contents', xapi_content)),
		update: (id, xapi_content) => (axios.put(`xapi_contents/${id}`, xapi_content)),
		delete: (id) => (axios.delete(`xapi_contents/${id}`)),
		show: (id) => (axios.get(`xapi_contents/${id}`)),
	},
	team_members: {
		index: () => (axios.get('team_members')),
		create: (team_member) => (axios.post('team_members', team_member)),
		update: (id, team_member) => (axios.put(`team_members/${id}`, team_member)),
		delete: (id) => (axios.delete(`team_members/${id}`)),
		show: (id) => (axios.get(`team_members/${id}`)),
	},
	corporates: {
		index: () => (axios.get('corporates')),
		create: (corporate) => (axios.post('corporates', corporate)),
		update: (id, corporate) => (axios.put(`corporates/${id}`, corporate)),
		delete: (id) => (axios.delete(`corporates/${id}`)),
		show: (id) => (axios.get(`corporates/${id}`)),
		getCertificates: (id) => (axios.get(`corporates/${id}/get_certifications`)),
		sync_user_invites: (id) => (axios.get(`corporates/${id}/sync_user_invites`)),
	},
	testimonials: {
		index: () => (axios.get('testimonials')),
		create: (testimonial) => (axios.post('testimonials', testimonial)),
		update: (id, testimonial) => (axios.put(`testimonials/${id}`, testimonial)),
		delete: (id) => (axios.delete(`testimonials/${id}`)),
		show: (id) => (axios.get(`testimonials/${id}`)),
	},
	xapi_statements: {
		index: (user_id) => (axios.get(`users/${user_id}/xapi_statements`)),
		create: (user_id, xapi_statement) => (axios.post(`users/${user_id}/xapi_statements`, xapi_statement)),
		update: (user_id, id, xapi_statement) => (axios.put(`users/${user_id}/xapi_statements/${id}`, xapi_statement)),
		delete: (user_id, id) => (axios.delete(`users/${user_id}/xapi_statements/${id}`)),
	},
	reports: {
		benchmarkResults: (report_params) => (axios.post('reports/benchmark_results', report_params)),
		benchmarkResultsUser: (report_params) => (axios.post('reports/benchmark_results_user', report_params)),
		userActivity: (report_params) => (axios.post('reports/user_activity', report_params)),
		certificateProgress: (report_params) => (axios.post('reports/certificate_progress', report_params)),
		selfAssessments: (report_params) => (axios.post('reports/self_assessments', report_params)),
		benchmarkComparison: (report_params) => (axios.post('reports/benchmark_comparison', report_params)),
		benchmarkComparisonCombined: (report_params) => (axios.post('reports/benchmark_comparison_combined', report_params)),
	},
	settings: {
		index: () => (axios.get('settings')),
		update: (id, setting) => (axios.put(`settings/${id}`, setting)),
		show: (id) => (axios.get(`settings/${id}`)),
	},
	podcast_award_categories: {
		index: () => (axios.get('podcast_award_categories')),
		create: (podcast_award_categories) => (axios.post('podcast_award_categories', podcast_award_categories)),
		update: (id, podcast_award_categories) => (axios.put(`podcast_award_categories/${id}`, podcast_award_categories)),
		delete: (id) => (axios.delete(`podcast_award_categories/${id}`)),
		show: (id) => (axios.get(`podcast_award_categories/${id}`)),
	},
	podcast_awards: {
		index: () => (axios.get('podcast_awards')),
		show: (id) => (axios.get(`podcast_awards/${id}`)),
	},
	podcast_award_ranks: {
		update_status: (id, status) => (axios.post(`podcast_award_ranks/${id}/update_status`, {status: status})),
	},
	podcast_reviews: {
		index: () => (axios.get('podcast_reviews')),
	},
	user_key_focus: {
		index: () => (axios.get('user_key_focuses')),
	},
	user_industry: {
		index: () => (axios.get('user_industries')),
	},
	user_seniority: {
		index: () => (axios.get('user_seniorities')),
	},
}