<template>
	<div>
		<v-btn color="primary" @click="$refs.file_input.$refs.input.click()" class="mb-2" v-if="button">
			<h4 class="text-uppercase">{{ label }}</h4>
			<v-icon class="ms-2">{{ $icons.upload }}</v-icon>
		</v-btn>
		<v-file-input
			:class="{'d-none': button}"
			@change="update"
			v-model="image"
			:value="value"
			@input="update"
			:label="label"
			:prepend-icon="$icons.image"
			:loading="iloading"
		></v-file-input>
		<v-img :src="image_url"></v-img>
	</div>

</template>

<script>
import helperMixin from "@/mixins/helperMixin";

export default {
	name: "TiImageUpload",
	props: {
		value: {
			required: true
		},
		label: {
			type: String
		},
		imageId: {},
		returnObject: {
			type: Boolean
		},
		button: {
			type: Boolean,
		}
	},
	mixins: [helperMixin],
	data: () => ({
		image: null,
		image_url: '',
		iloading: false
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.loadImage()
		},
		loadImage() {
			if (this.imageId) {
				this.iloading = true;
				this.$api.images.show(this.imageId)
					.then((response) => {
						this.image_url = `${response.data.thumb}`
					})
					.catch(error => {
						this.$toast.add(error.response.statusText, 'error')
					})
				.finally(() => this.iloading = false)
			} else {
				this.image_url = null
			}
		},
		update() {
			let form = new FormData
			form.append('image[image]', this.image)
			this.iloading = true;
			this.$api.images.create(form)
				.then(response => {
					this.image_url = `${response.data.thumb}`
					this.$emit('input', response.data.id);
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error')
				})
				.finally(() => this.iloading = false)
		},
	}
}
</script>