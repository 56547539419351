// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {directives: {ripple}})

const theme = {
	primary: '#5099a5',
	secondary: '#4f545a',
	accent: '#7264b9',
	info: '#00CAE3',
	success: '#549c37',
	warning: '#FB8C00',
	error: '#d3555b',
	light: '#eeeeee',
}

export default new Vuetify({
	breakpoint: {mobileBreakpoint: 960},
	theme: {
		themes: {
			dark: theme,
			light: theme,
		},
	},
})
