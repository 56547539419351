// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	trainingCourses:[],
	trainingCourse: {},
}

const getters = {
	...make.getters(state),
}


const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getTrainingCourses: ({ dispatch }) => {
		api.training_courses.index()
			.then(response => dispatch('setTrainingCourses', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getTrainingCourse: ({ dispatch }, id) => {
		api.training_courses.show(id)
			.then(response => dispatch('setTrainingCourse', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
