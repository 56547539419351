import _find from 'lodash/find';

export default {
	data: () => ({
		resourceStatuses: [
			{
				id: 0,
				label: 'Draft',
				value: 'draft'
			},
			{
				id: 1,
				label: 'Published',
				value: 'published'
			},
			{
				id: 2,
				label: 'Deleted',
				value: 'deleted'
			},
		]
	}),
	methods: {
		expertiseColor: (expertise) => {
			if (expertise <= 30) return 'light-green';
			if (expertise <= 70) return 'orange darken-2';
			return 'deep-orange darken-4';
		},
		expertiseLabel: (expertise) => {
			if (expertise <= 30) return 'Foundation';
			if (expertise <= 70) return 'Intermediate';
			return 'Advanced';
		},
		slugify: (string) => {
			let url = encodeURI(string)
			return url.split('%20').join('-').toLowerCase();
		},
		getBrandName: (brands, id) => {
			let res = _find(brands, (brand) => brand.id === id)
			return res?.name
		},
	},
}