// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';
import models from "../../models";

// Data
const state = {
	corporates:[],
	corporate: models.Corporate()
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getCorporates: ({ dispatch }) => {
		api.corporates.index()
			.then(response => dispatch('setCorporates', response.data))
			.catch(error => {
				console.log(error);
				toast.add(error.message, 'error')
			})
	},
	getCorporate: ({ dispatch }, id) => {
		api.corporates.show(id)
			.then(response => dispatch('setCorporate', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
