<template>
	<div>
		<div @click.stop="toggle = true">
			<slot></slot>
		</div>


		<v-dialog v-model="toggle" width="500">
			<v-card>
				<v-card-title class="text-h5 error white--text">
					{{ title }}
				</v-card-title>

				<v-card-text class="my-5 py-0">
					<p class="black--text" v-html="message"></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" text @click="toggle = false">cancel</v-btn>
					<v-btn color="error"  @click="destroy">{{ buttonText }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "TiConfirm",
	props:{
		title:{
			type: String,
			default: 'Are you sure?'
		},
		message:{
			type: String,
			required: true
		},
		buttonText:{
			type: String,
			default: "I'm sure"
		}
	},
	data: () => ({
	    toggle: false
	}),
	methods:{
		destroy(){
			this.toggle = false
			this.$emit('confirm', true)
		},
		toggleDialog(){
			this.toggle = true
		}
	}
}
</script>