// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';
import models from "../../models";

// Data
const state = {
	podcast_awards: [
		models.PodcastAward()
	],
	podcast_award: models.PodcastAward(),
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getPodcastAwards: ({commit}) => {
		api.podcast_awards.index()
			.then(response => commit('podcast_awards', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getPodcastAward: ({commit}, id) => {
		api.podcast_awards.show(id)
			.then(response => commit('podcast_award', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
