// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';

// Data
const state = {
	documents: [],
	document: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getDocuments: (context) =>
		api.documents.index()
			.then((response) => context.commit('documents', response.data))
			.catch((error) => console.log(error)),

	getDocument: (context, id) =>
		api.documents.show(id)
			.then((response) => context.commit('document', response.data))
			.catch(error => toast.add(error.message, 'error')),

	deleteDocument: (context, id) => {
		api.documents.delete(id)
			.then((response) => {
				context.commit('document', response.data)
				this.$router.push({name: 'Documents'})
			})
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
