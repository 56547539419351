<template>
	<div>
		<v-btn color="error" elevation="2" fab small class="mr-4" @click="toggle = !toggle">
			<v-icon>{{ $icons.delete }}</v-icon>
		</v-btn>

		<v-dialog v-model="toggle" width="500">

			<v-card>
				<v-card-title class="text-h5 error white--text">
					Confirm Delete
				</v-card-title>

				<v-card-text class="my-5 py-0">
					<p class="black--text">Are you sure you want to delete this item?</p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" text @click="toggle = false">cancel</v-btn>
					<v-btn color="error"  @click="destroy">delete</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "TiDelete",
	data: () => ({
	    toggle: false
	}),
	methods:{
		destroy(){
			this.toggle = false
			this.$emit('destroy', true)
		},
		toggleDialog(){
			this.toggle = true
			console.log('hello');
		}
	}
}
</script>