<template>
	<v-slider
		:label="expertiseLabel(value)"
		:hint="hint"
		max="100"
		thumb-label="always"
		:thumb-color="expertiseColor(value)"
		:color="expertiseColor(value)"
		:track-color="expertiseColor(value)"
		:persistent-hint="persistentHint"
		min="0"
		:step="step"
		:value="value"
		@input="update"
	></v-slider>
</template>

<script>
import helperMixin from "../mixins/helperMixin";
export default {
	name: "TiExpertiseSlider",
	props: {
		value: {},
		persistentHint: {},
		step:{
			default: '10'
		},
		hint:{
			default: 'What is the difficulty?'
		}
	},
	mixins: [helperMixin],
	methods: {
		update(newValue) {
			this.$emit('input', newValue);
		}
	}
}
</script>