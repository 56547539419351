// Pathify
import {make} from 'vuex-pathify'
import api from "@/services/api";
import {toast} from '@/util/helpers';
import models from "../../models";

// Data
const state = {
	podcast_award_categories: [
		models.PodcastAwardCategory()
	],
	podcast_award_category: models.PodcastAwardCategory(),
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getPodcastAwardCategories: ({commit}) => {
		api.podcast_award_categories.index()
			.then(response => commit('podcast_award_categories', response.data))
			.catch(error => toast.add(error.message, 'error'))
	},
	getPodcastAwardCategory: ({commit}, id) => {
		api.podcast_award_categories.show(id)
			.then(response => commit('podcast_award_category', response.data))
			.catch(error => toast.add(error.message, 'error'))
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
