<template>
	<v-row>
		<v-col cols="12" lg="4" order-lg="12" v-if="hasSidebar">
			<v-sheet elevation="3" class="pa-4">
				<v-row>
					<v-col cols="12">
						<h4 class="text-h4">Additional Settings</h4>
					</v-col>
					<slot name="sidebar"></slot>
				</v-row>
			</v-sheet>

		</v-col>
		<v-col cols="12" :lg="hasSidebar ? 8 : 12" order-lg="1">
			<v-sheet elevation="3" class="pa-4">
				<v-row>
					<v-col cols="12" v-if="!hideTitle">
						<h4 class="text-h4">{{ title }}</h4>
					</v-col>
					<slot></slot>
				</v-row>
			</v-sheet>

		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "TiFormLayout",
	props: {
		title: {
			type: String,
			default: 'Main settings'
		},
		hideTitle: Boolean
	},
	computed: {
		hasSidebar() {
			return !!this.$slots.sidebar;
		},
		mainTitle() {
			return this.title || "Main settings"
		}
	}
}
</script>

<style scoped>

</style>